import Vue from 'vue'
import VueRouter from 'vue-router'
import { v4 as uuidv4, validate as uuidValidate } from 'uuid'
import axios from '@/config/axios'
import { initRouteInfo, findFront, findName, queryCheck, dummyRoutes } from '@/utils/routerUtils.js'

// Layouts
import authorization from '@/views/Authorization/router'
import profile from '@/views/Profile/router'
import menu from '@/views/Menu/router'
import error from '@/views/Error/router'
// import webview from '@/views/WebView/router'

Vue.use(VueRouter)
let firstEntry = true

export default function setupRouter({ store, prototype }) {
    const appRoute = store.state.navigation?.routerApp?.length ? [...store.state.navigation.routerApp] : [],
        frontPage = findFront(appRoute)

    const routes = [
        {
            name: 'home',
            path: '/',
            component: () => import('@/layouts/Dashboard'),
            redirect: { name: frontPage },
            children: [...appRoute, ...profile, ...menu, ...dummyRoutes]
            // , ...splitview
        },
        {
            path: '/user',
            component: () => import('@/layouts/Authorization'),
            redirect: { name: 'login' },
            children: authorization
        },
        /*{
            name: 'webview',
            path: '/webview',
            component: () => import('@/layouts/WebView'),
            children: [...webview]
        },*/
        {
            path: '*',
            component: () => import('@/layouts/PageError'),
            redirect: { name: 'page_404' },
            children: error
        }
    ]


    const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    })

    router.beforeEach(async (to, from, next) => {
        const user = store.state.user.user,
            query = queryCheck(to)

        if (user) {
            if (firstEntry) {
                try {
                    await initRouteInfo({ to, from, init: true, prototype })
                        
                    if (!findName(authorization, to.name)) {
                        if(to.name) {
                            if(to.name === 'notifications_dummy') {
                                return next({ name: findFront(appRoute), query })
                            } else {
                                if(
                                    to.name === 'projects-list' ||
                                    to.name === 'projects-gant' ||
                                    to.name === 'home' || 
                                    to.name === 'menu' || 
                                    to.name === 'profile' || 
                                    to.name === 'create_order' || 
                                    to.name === 'create_return_order' || 
                                    to.name === 'full_invest_project' || 
                                    to.name === 'full_invest_project_info' || 
                                    to.name === 'full_invest_project_timeline' ||
                                    to.name === 'full_sports_facilities_pasport' || 
                                    to.name === 'full_sports_facilities_repair' || 
                                    to.name === 'full_sports_facilities_sections' || 
                                    to.name === 'full_sports_facilities_technical' || 
                                    to.name === 'full_sports_facilities_files' || 
                                    to.name === 'full_sports_facilities_gallery' || 
                                    to.name === 'full_sports_facilities_history' || 
                                    to.name === 'full_sports_facilities_section_information' ||
                                    to.name === 'full_sports_facilities_object_information' ||
                                    to.name === 'full_sports_facilities') {
                                    return next({ query })
                                } else {
                                    if(to.name === 'page_404') {
                                        return next()
                                    } else {
                                        if(query.chat_id) {
                                            const findChat = appRoute.find(f => (f.name === 'chat' || to.name === 'chat-contact' || to.name === 'chat-body') && f.isShowMobile)
                                            if(findChat) {
                                                if(to.name === 'chat-contact' || to.name === 'chat-body') {
                                                    if(to.params?.id === query.chat_id) {
                                                        return next({ query })
                                                    } else {
                                                        return next({ name: 'chat-body', params: { id: query.chat_id } })
                                                    }
                                                } else {
                                                    return next({ name: 'chat-body', params: { id: query.chat_id } })
                                                }
                                            } else {
                                                const findRoute = appRoute.find(f => f.name === to.name)
                                                if(findRoute.isShowMobile) {
                                                    return next({ query })
                                                } else {
                                                    return next({ name: findFront(appRoute), query })
                                                }
                                            }
                                        } else {
                                            if(to.name === 'chat-contact' || to.name === 'chat-body') {
                                                return next({ query })
                                            } else {
                                                const findRoute = appRoute.find(f => f.name === to.name)
                                                if(findRoute.isShowMobile) {
                                                    return next({ query })
                                                } else {
                                                    return next({ name: findFront(appRoute), query })
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            return next({ name: 'home', query })
                        }
                    } else {
                        return next({ name: 'home', query })
                    }
                } catch (e) {
                    console.log(e)
                }
            } else {
                await initRouteInfo({ to, from, init: false, prototype })
                if (!findName(authorization, to.name)) {
                    return next()
                } else {
                    location.reload()
                }
            }
        } else {
            if(to.name === 'joinUser') {
                if(to.query?.token?.length && uuidValidate(to.query.token)) {
                    const token = to.query.token
                    const { data } = await axios.get('/users/my_organizations/invite/check/', {
                        params: {
                            token
                        }
                    })
                    if(data) {
                        return next({ query })
                    } else {
                        Vue.prototype.$message.warning('Токен не действителен', 5)
                        return next({ name: 'login', query })
                    }
                } else {
                    Vue.prototype.$message.warning('Токен не действителен', 5)
                    return next({ name: 'login', query })
                }
            } else {
                if (!findName(authorization, to.name)) {
                    return next({ name: 'login', query })
                } else {
                    return next({ query })
                }
            }
        }
    })

    router.afterEach((to, from) => {
        if (to.name !== 'Home' && to.name !== 'home' && !findName(authorization, to.name) && to.path !== from.path) {
            let route = Object.assign({}, to)
            if (route.fullPath.includes('?')) {
                route.fullPath = route.fullPath.split('?')[0]
                route.query = {}
            }
        }

        // Отклоняю первый вход
        firstEntry = false
    })

    return router
}
